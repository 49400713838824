export class API {
  static async get(host: string, path: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    try {
      const response = await fetch(
        `${host}${path}`,
        {
          method: 'GET',
          headers
        }
      );

      return response.json();
    } catch (error) {
      return error;
    }
  }
}