import { Formatter } from "./Formatter";
import { months } from "../definitions/Constants";

export class URLUtility {
  static generateInvoiceQuery(
    taxNumber: string,
    filter: {
      year: string,
      month: string,
      status: string
    } | undefined
  ): string {
    if (filter) {
      const date = `${parseFloat(filter.year) - 543}-${Formatter.toStringPrefixNumber(months.indexOf(filter.month) + 1)}-01`;

      return filter.status === 'all' ?
      `/api/v1/buyers${taxNumber}/invoices?date=${date}`:
      `/api/v1/buyers${taxNumber}/invoices?date=${date}&status=${filter.status}`;
    } else {
      return `/api/v1/buyers${taxNumber}/invoices`;
    }
  }
}