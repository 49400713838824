import { InvoicesResultEntity } from "../entities/InvoicesResultEntity";
import { API } from "../utils/API";
import { api } from '../utils/Environment';
import { URLUtility } from "../utils/URLUtility";

export class InvoiceDatasource {
  static async getInvoicesByTaxNumber(
    taxNumber: string,
    filter: {
      year: string,
      month: string,
      status: string
    } | undefined
  ): Promise<InvoicesResultEntity> {
    return API.get(api, URLUtility.generateInvoiceQuery(taxNumber, filter));
  }
}