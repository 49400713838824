import React from 'react';
import { history } from '../../utils/config';
import signOut from '../../assets/signout.svg';
import billmeLogo from '../../assets/billme-logo.png';
import { Button } from '../Button';
import { Selector } from '../Selector';
import { SideTabProps } from './type';
import './index.css';

export class SideTab extends React.Component<SideTabProps> {
  render() {
    return (
      <div className="side-tab">
        <img className="side-tab__logo" src={billmeLogo} alt="" />
        <p className="side-tab__description">การกรองข้อมูล</p>
        <div className="side-tab__filter">
          <Selector
            id="filter--year"
            name="filterByYear"
            title="ปี พ.ศ."
            selected={this.props.filterByYear}
            options={["2560", "2561", "2562", "2563", "2564", "2565", "2566", "2567", "2568", "2569" ,"2570"]}
            onSelectOption={this.props.onSelected}
          />
          <Selector
            id="filter--month"
            name="filterByMonth"
            title="เดือน"
            selected={this.props.filterByMonth}
            options={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
            translator={{
              Jan: "มกราคม", Feb: "กุมภาพันธุ์", Mar: "มีนาคม", Apr: "เมษายน",
              May: "พฤษภาคม", Jun: "มิถุนายน", Jul: "กรกฎาคม", Aug: "สิงหาคม",
              Sep: "กันยายน", Oct: "ตุลาคม", Nov: "พฤศจิกายน", Dec: "ธันวาคม"
            }}
            onSelectOption={this.props.onSelected}
          />
          <Selector
            id="filter--status"
            name="filterByStatus"
            title="สถานะ"
            selected={this.props.filterByStatus}
            options={["all", "unpaid", "pending", "paid"]}
            translator={{all: "ทุกสถานะ", unpaid: "ยังไม่ชำระ", pending: "รอตรวจสอบ", paid: "ชำระแล้ว"}}
            onSelectOption={this.props.onSelected}
          />
        </div>
        <div className="side-tab__footer">
          <Button
            type="remove-flat"
            title="ออกจากระบบ"
            icon={signOut}
            onClick={() => history.push('/')}
          />
        </div>
      </div>
    );
  }
}