import React from 'react';
import mainPicture from '../assets/main.png';
import billmeLogo from '../assets/billme-logo--white.png';
import { Button } from '../components/Button';
import { TextField } from '../components/TextField';
import { history } from '../utils/config';
import '../styles/Search.css';

export class Search extends React.Component<{}, {taxNumber: string}> {
  constructor(props: {}) {
    super(props);

    this.state = {
      taxNumber: ''
    };
  }

  render() {
    return (
      <div id="search-page" className="page billme-bg">
        <div className="container">
          <img className="billme-logo" src={billmeLogo} alt="" />
          <div className="box">
            <img className="logo--big" src={mainPicture} alt="" />
            <h1 className="title">รายการเรียกเก็บเงิน</h1>
            <p className="description">ระบบแจ้งรายการเรียกเก็บเงิน และจัดเก็บเอกสาร</p>
            <div className="flex-box">
              <TextField
                title="เลขที่ผู้เสียภาษี"
                example="เลขนิติบุคคล หรือเลขบัตรประชาชน"
                type="text"
                value={this.state.taxNumber}
                onChange={(e) => this.setState({taxNumber: e.target.value})}
              />
              <Button
                title="ค้นหา"
                type="primary"
                onClick={() => history.push(`/${this.state.taxNumber}`)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
