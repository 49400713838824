import React from 'react';
import ReactModal from 'react-modal';
import coinIcon from '../assets/coin.svg';
import downloadIcon from '../assets/download.svg';
import downloadDisableIcon from '../assets/download-disabled.svg';
import noResult from '../assets/no-result.svg';
import { Button } from '../components/Button';
import { SideTab } from '../components/SideTab';
import { months } from '../definitions/Constants';
import { HeaderBar } from '../components/HeaderBar';
import { StatusBadge } from '../components/StatusBadge';
import { InvoiceFacade } from '../facades/InvoiceFacade';
import { Spinner } from '../components/Spinner';
import { billPouch } from '../utils/Environment';
import { history } from '../utils/config';
import { ResultState, InvoiceData } from '../types/ResultType';
import '../styles/Result.css';

export class Result extends React.Component<{}, ResultState> {
  constructor(props: {}) {
    super(props);

    const date = new Date();
    const url = new URL(document.location.href);
    const filterByYear = url.searchParams.get('year');
    const filterByMonth = url.searchParams.get('month');
    const filterByStatus = url.searchParams.get('status');
    const filter = filterByYear && filterByMonth && filterByStatus ? {
      year: filterByYear,
      month: filterByMonth,
      status: filterByStatus
    } : undefined;

    this.state = {
      totalAmount: '0.00',
      totalInvoice: '0',
      filterByYear: filterByYear ? filterByYear : `${date.getFullYear() + 543}`,
      filterByMonth: filterByMonth ? filterByMonth : `${months[date.getMonth()]}`,
      filterByStatus: filterByStatus ? filterByStatus : 'all',
      invoices: [],
      isShowWaitingModal: true
    };

    InvoiceFacade.getInvoicesByTaxNumber(url.pathname, filter).then(result => {
      if (result) {
        this.setState({
          invoices: result.invoices,
          totalAmount: result.totalAmount,
          totalInvoice: result.totalInvoice,
          isShowWaitingModal: false
        });
      }
    });
  }

  render() {
    return (
      <div id="result-page" className="page page--complex">
        <div className="result-page__container">
          <SideTab
            filterByYear={this.state.filterByYear}
            filterByMonth={this.state.filterByMonth}
            filterByStatus={this.state.filterByStatus}
            onSelected={(name, value) => this.handleFilter(name, value, this.state)}
          />
          <div className="result-page__content">
            <HeaderBar title="รายการเรียกเก็บเงิน" />
            <div className="content__result">
              <section id="summary" className="result-summary">
                <img className="icon" src={coinIcon} alt="" />
                <div className="summary-detail">
                  <h1 className="title">{`รายการเรียกเก็บเงินทั้งหมด ${this.state.totalAmount} บาท`}</h1>
                  <p className="description">{`จำนวน ${this.state.totalInvoice} รายการ`}</p>
                </div>
              </section>
              <section id="invoices" className="invoices">
                <table className="table">
                  <thead className="table-header">
                    <tr className="table-row">
                      <th className="invoice-status">สถานะการชำระ</th>
                      <th className="invoice-amount">จำนวนเงิน (บาท)</th>
                      <th className="invoice-due-date">ครบกำหนด</th>
                      <th className="invoice-description">รายละเอียดการแจ้งหนี้</th>
                      <th className="download-invoice"></th>
                      <th className="download-receipt"></th>
                      <th className="download-tax-invoice"></th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      this.state.invoices.length ?
                      this.renderInvoices(this.state.invoices) :
                      this.renderEmptyResult()
                    }
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </div>
        <ReactModal
          className="waiting"
          isOpen={this.state.isShowWaitingModal}
        >
          <Spinner />
        </ReactModal>
      </div>
    );
  }

  renderInvoices(invoices: InvoiceData[]) {
    return invoices.map((invoice, index) => {
      return (
        <tr className="table-row" key={`invoice--${index}`}>
          <td className="invoice-status" onClick={() => this.gotoBillPouch(invoice.id)}>
            <StatusBadge status={invoice.status} />
          </td>
          <td className="invoice-amount" onClick={() => this.gotoBillPouch(invoice.id)}>
            {invoice.amount}
          </td>
          <td className="invoice-due-date" onClick={() => this.gotoBillPouch(invoice.id)}>
            {invoice.dueDate}
          </td>
          <td className="invoice-description" onClick={() => this.gotoBillPouch(invoice.id)}>
            <div className="description-box">
              <p className="box-text">{invoice.description}</p>
              <p className="box-sub-text">{invoice.seller}</p>
            </div>
          </td>
          <td className="download-invoice">
            <Button
              type="primary-flat"
              title="ใบแจ้งหนี้"
              icon={!invoice.documents.invoice ? downloadDisableIcon : downloadIcon}
              disabled={!invoice.documents.invoice}
              onClick={() => invoice.documents.invoice ? this.downloadDocument(invoice.documents.invoice) : ''}
            />
          </td>
          <td className="download-receipt">
            <Button
              type="primary-flat"
              title="ใบเสร็จ"
              icon={!invoice.documents.receipt ? downloadDisableIcon : downloadIcon}
              disabled={!invoice.documents.receipt}
              onClick={() => invoice.documents.receipt ? this.downloadDocument(invoice.documents.receipt) : ''}
            />
          </td>
          <td className="download-tax-invoice">
            <Button
              type="primary-flat"
              title="ใบกำกับภาษี"
              icon={!invoice.documents.taxInvoice ? downloadDisableIcon : downloadIcon}
              disabled={!invoice.documents.taxInvoice}
              onClick={() => invoice.documents.taxInvoice ? this.downloadDocument(invoice.documents.taxInvoice) : ''}
            />
          </td>
        </tr>
      );
    });
  }

  renderEmptyResult() {
    return (
      <tr className="table-row no-hover">
        <td colSpan={7} className="">
          <div className="empty-result">
            <img className="no-result__image" src={noResult} alt="" />
            <h1 className="title">ไม่พบรายการเรียกเก็บเงินในช่วงเวลาดังกล่าว</h1>
            <p className="description">หากท่านต้องการค้นหารายการเรียกเก็บเงินอื่น ๆ</p>
            <p className="description">สามารถเลือกตัวเลือกการกรองข้อมูลด้านซ้ายได้</p>
          </div>
        </td>
      </tr>
    );
  }

  handleFilter(name: string, value: string, state: ResultState) {
    this.setState({ [name]: value });
    const filter = {
      year: name === 'filterByYear' ? value : state.filterByYear,
      month: name === 'filterByMonth' ? value : state.filterByMonth,
      status: name === 'filterByStatus' ? value : state.filterByStatus
    };
    history.push(`?year=${filter.year}&month=${filter.month}&status=${filter.status}`);

    this.setState({
      isShowWaitingModal: true
    })

    InvoiceFacade.getInvoicesByTaxNumber(document.location.pathname, filter).then(result => {
      this.setState({
        invoices: result.invoices,
        totalAmount: result.totalAmount,
        totalInvoice: result.totalInvoice,
        isShowWaitingModal: false
      });
    });

  }

  downloadDocument(url: string) {
    const element = document.createElement("a");
    element.href = url;
    element.download = url;
    element.click();
  }

  gotoBillPouch(id: string) {
    const element = document.createElement("a");
    element.href = `${billPouch}/${id}`;
    element.target = '_blank';
    element.click();
  }
}