export class ElementUtility {
  static isParentElement(element: Element, id: string) {
    while (element) {
      if (element.id && element.id.indexOf(id) > -1) {
        return true;
      }

      element = element.parentElement!;
    }

    return false;
  }
}