import * as React from 'react';
import MDSpinner from 'react-md-spinner';
import './style.css';

export class Spinner extends React.Component {
  render() {
    return (
      <div className="Spinner">
        <MDSpinner singleColor="#00ace1" size={48}/>
      </div>
    );
  }
}