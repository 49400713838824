import React from 'react';
import { HeaderProps } from './type';
import './index.css';


export class HeaderBar extends React.Component<HeaderProps> {
  render() {
    return (
      <div className="header-bar">
        <h1 className="title">{this.props.title}</h1>
      </div>
    );
  }
}