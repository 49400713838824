import * as React from 'react';
import { TextFieldProps, TextFieldState } from './type';
import './index.css';

export class TextField
  extends React.Component<TextFieldProps, TextFieldState> {

  input: HTMLInputElement | null = null;

  constructor(props: TextFieldProps) {
    super(props);

    this.state = {
      inputFocus: '',
      labelFloat: '',
      renderValue: this.props.value!
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    this.props.autoFocus ? this.handleFocus() : this.handleBlur();
  }

  componentWillReceiveProps(nextProps: TextFieldProps) {
    this.setState({
      renderValue: nextProps.renderFormatter ?
      nextProps.renderFormatter!(nextProps.value!) :
      nextProps.value!
    });

    this.handleBlur();
  }

  render() {
    const {
      title, name, id, disabled, required,
      renderFormatter, autoFocus, placeHolder,
      type, example, isValid, className, autoComplete
    } = this.props;
    return (
      <div className={`${this.textFieldClassName(isValid)} ${className}`}>
        <input
          id={id}
          className="text-field__input"
          type={type}
          ref={e => this.input = e!}
          placeholder={placeHolder}
          name={name}
          disabled={disabled}
          required={required}
          autoFocus={autoFocus}
          onFocus={this.handleFocus}
          autoComplete={autoComplete}
          onKeyPress={e => {
            if (e.key === 'Enter' && this.props.onEnter) {
              this.props.onEnter();
            }
          }}
          onBlur={this.handleBlur}
          value={this.state.renderValue}
          onChange={(event) => {
            const value = event.target.value;
            const renderValue = renderFormatter ? renderFormatter(value) : value;

            this.setState({renderValue});

            if (this.props.onChange) {
              this.props.onChange(event);
            }
          }}
        />
        {title ? <label className={`text-field__label ${this.state.labelFloat}`}>{title}</label> : null}
        {
          example || required ?
          <label className={`text-field__example ${this.isValid(isValid)}`}>
            {required ? '* จำเป็นต้องกรอก' : example}
          </label> :
          null
        }
      </div>
    );
  }

  textFieldClassName(isValid: boolean | undefined) {
    return `text-field text-field--upgraded ${this.isValid(isValid)} ${this.state.inputFocus} ${this.isRequired()}`;
  }

  isValid(value: boolean | undefined) {
    return value === undefined ? '' : value ? 'not-valid' : '';
  }

  handleFocus() {
    this.setState({
      inputFocus: 'text-field--focused',
      labelFloat: 'text-field__label--float-above'
    });
  }

  handleBlur() {
    const ONE_TENTH_SEC = 100;

    if (!(document.activeElement === this.input)) {
      setTimeout(() => this.checkAutoFill(), ONE_TENTH_SEC);
    }
  }

  checkAutoFill() {
    if (this.state.renderValue) {
      this.setState({
        inputFocus: '',
        labelFloat: 'text-field__label--float-above'
      });
    } else {
      this.setState({
        inputFocus: '',
        labelFloat: ''
      });
    }
  }

  isRequired() {
    return this.props.required ? 'required' : '';
  }
}