import React from 'react';
import { StatusBadgeProps } from './type';
import { Translation } from '../../definitions/Translation';
import './index.css';

export class StatusBadge extends React.Component<StatusBadgeProps> {
  render() {
    return (
      <div className={`status-badge ${this.props.status}`}>
        <p className="status-title">{Translation.invoiceStatus[this.props.status]}</p>
      </div>
    );
  }
}