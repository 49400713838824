export const api = apiEnvironment();
export const billPouch = billPouchEnvironment();

function apiEnvironment() {
  const location = document.location.hostname;

  return location === 'my.billme.co.th' ?
  'https://billbot.billme.co.th' :
  'https://staging-billbot.billme.co.th';
}

function billPouchEnvironment() {
  const location = document.location.hostname;

  return location === 'my.billme.co.th' ?
  'https://billpouch.billme.co.th' :
  'https://staging-billpouch.billme.co.th';
}