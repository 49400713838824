import { InvoiceData } from "../types/ResultType";
import { InvoicesResultEntity, Attachment } from "../entities/InvoicesResultEntity";
import { Formatter } from "../utils/Formatter";

export interface InvoicesResultModel {
  invoices: InvoiceData[];
  totalAmount: string;
  totalInvoice: string;
}

export class InvoicesResultModel implements InvoicesResultModel {
  constructor(data: InvoicesResultEntity) {
    this.invoices = data.invoices.map(invoice => {
      return {
        id: invoice.id,
        status: invoice.status,
        seller: invoice.seller.name,
        amount: Formatter.toStringCurrency(invoice.gross_amount),
        dueDate: Formatter.toThaiDate(invoice.due_date),
        description: invoice.description,
        documents: {
          invoice: InvoicesResultModel.getDocument('invoice', invoice.attachments),
          receipt: InvoicesResultModel.getDocument('receipt', invoice.attachments),
          taxInvoice: InvoicesResultModel.getDocument('tax_invoice', invoice.attachments)
        }
      }
    });
    this.totalInvoice = Formatter.toStringNumber(data.invoices.length);
    this.totalAmount = Formatter.toStringCurrency(
      data.invoices
      .map(invoice => invoice.gross_amount)
      .reduce((cur, next) => cur + next, 0)
    );
  }

  private static getDocument(name: string, attachments: Attachment[]): string | null {
    const document = attachments.filter(attachment => attachment.type === name);

    return document.length ? document[0].url : null;
  }
}