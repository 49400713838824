import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { history } from './utils/config';
import { Search } from './pages/Search';
import { Result } from './pages/Result';
import './styles/App.css';


export default class App extends React.Component {
  render () {
    return (
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route
              path="/:id"
              exact={true}
              component={() => <Result />}
            />
            <Route
              path="/"
              exact={true}
              component={() => <Search />}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}
