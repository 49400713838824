import { InvoicesResultModel } from '../models/InvoicesResultModel'
import { InvoiceDatasource } from '../datasources/InvoiceDatasource';

export class InvoiceFacade {
  static async getInvoicesByTaxNumber(
    taxNumber: string,
    filter: {
      year: string,
      month: string,
      status: string
    } | undefined
  ): Promise<InvoicesResultModel> {
    const result = await InvoiceDatasource.getInvoicesByTaxNumber(taxNumber, filter);

    return new InvoicesResultModel(result)
  }
}