import numeral from "numeral";

export class Formatter {
  static cleanNumberString(value: string) {
   return value.replace(/\D/g, "");
  }

  static toStringPrefixNumber(value: number) {
    return numeral(Formatter.cleanNumberString(value.toString())).format("00");
  }

  static toStringNumber(value: number) {
    return numeral(Formatter.cleanNumberString(value.toString())).format("0,0");
  }

  static toStringCurrency(value: number) {
    return numeral(Formatter.cleanNumberString(value.toString())).format("0,0.00");
  }

  static toThaiDate(date: string) {
    const newDate = new Date(date).toLocaleDateString();
    const monthTh = [
      "Empty", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.",
      "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.",
      "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
    ];
    const splitDate = newDate.split('/')
    const day = splitDate[1];
    const month = monthTh[parseFloat(splitDate[0])];
    const year = parseFloat(splitDate[2]) + 543;

    return `${day} ${month} ${year}`;
  }
}