import * as React from "react";
import { ButtonProps } from "./type";
import "./style.css";

export class Button extends React.Component<ButtonProps> {
  render() {
    return (
      <button
        className={`button ${this.props.type}`}
        onClick={this.props.onClick}
        disabled={!!this.props.disabled}
      >
        {
          this.props.icon ?
          <img className="button-icon" src={this.props.icon} alt="" /> :
          null
        }
        {this.props.title}
      </button>
    );
  }
}
