export class Translation {
  static invoiceStatus: InvoiceStatus = {
    unpaid: 'ยังไม่ชำระ',
    pending: 'รอตรวจสอบ',
    paid: 'ชำระแล้ว'
  }
}

type InvoiceStatus = {
  [x: string]: string;
}